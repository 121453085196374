import Repository from "../repository";

let type = process.env.VUE_APP_API_TYPE;
let resource = "prayer-requests";

let url = `${type}/${resource}`;

export default {
  get(payload = {}) {
    return Repository.get(url, payload);
  },
  delete(id){
    return Repository.delete(`${url}/${id}`);
  }
};
