import Repository from "../repository";

let type = process.env.VUE_APP_API_TYPE;
let resource = "users";

let url = `${type}/${resource}`;

export default {
  get(payload = {}) {
    return Repository.get(url, payload);
  },
  show(id, payload = {}) {
    return Repository.get(`${url}/${id}`, payload);
  },
  create(payload = {}) {
    return Repository.post(`${type}/register`, payload);
  },
  update(id, payload = {}) {
    return Repository.put(`${url}/${id}`, payload);
  },
  search(payload = {}) {
    return Repository.get(url, payload);
  },
  delete(id) {
    return Repository.delete(`${url}/${id}`);
  },
  exportToCsv(payload = {}) {
    return Repository.get(`${url}/export`, payload);
  }
};
