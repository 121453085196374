import Repository from "../repository";

let type = process.env.VUE_APP_API_TYPE;
let resource = "donation";

let url = `${type}/${resource}`;

export default {
  get(payload = {}) {
    return Repository.get(url, payload);
  },
  exportToCsv(payload = {}) {
    return Repository.get(`${url}/export`, payload);
  },
  getDonationLogs(payload = {}) {
    return Repository.get(`${type}/donation-logs`, payload);
  },
};
