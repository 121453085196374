import Repository from "../repository";

let type = process.env.VUE_APP_API_TYPE;
let resource = "teachings";

let url = `${type}/${resource}`;

const formatPayload = ({
  thumbnail,
  title,
  sub_title,
  description,
  teaching_file,
  teaching_description,
  discussion_title,
  discussion_description,
  teaching_questions,
  is_featured
}) => {
  const formData = new FormData();
  if (thumbnail) {
    formData.set("thumbnail", thumbnail);
  }
  formData.set("title", title);
  formData.set("sub_title", sub_title);
  formData.set("description", description);
  if (teaching_file) {
    formData.set("teaching_file", teaching_file);
  }
  formData.set("teaching_description", teaching_description);
  formData.set("discussion_title", discussion_title);
  formData.set("discussion_description", discussion_description);
  if (teaching_questions.length > 0) {
    teaching_questions.forEach((item, index) => {
      formData.append(`teaching_questions[${index}][question]`, item.question);
      formData.append(
        `teaching_questions[${index}][is_required]`,
        item.is_required ? 1 : 0
      );
    });
  }
  formData.set("is_featured", is_featured ? 1 : 0);

  return formData;
};

export default {
  get(payload = {}) {
    return Repository.get(url, payload);
  },
  show(id = {}) {
    return Repository.get(`${url}/${id}`);
  },
  create(payload = {}) {
    return Repository.post(url, formatPayload(payload));
  },
  update(id, payload = {}) {
    if (typeof payload.thumbnail === "string") {
      payload.thumbnail = null;
    }
    if (typeof payload.teaching_file === "string") {
      payload.teaching_file = null;
    }
    return Repository.post(`${url}/${id}?_method=PUT`, formatPayload(payload));
  }
};
