import Repository from "../repository";

export default {
  login(payload) {
    return Repository.post(`api/login`, payload);
  },
  logout(payload = {}) {
    return Repository.post(`api/logout`, payload);
  },
  sendResetPasswordLink(payload = {}) {
    return Repository.post(`password/email`, payload);
  }
};
