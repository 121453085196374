import Repository from "../repository";

let type = process.env.VUE_APP_API_TYPE;
let resource = "announcements";

let url = `${type}/${resource}`;

export default {
    get(payload = {}) {
        return Repository.get(url, payload);
    },

    create(payload = {}) {
        return Repository.post(url, payload);
    },
}