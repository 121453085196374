import signUpRepository from "./signUpRepository";
import devotionsRepository from "./devotionsRepository";
import dailyVideosRepository from "./dailyVideosRepository";
import worshipSongsRepository from "./worshipSongsRepository";
import messagesRepository from "./messagesRepository";
import connectGroupChatsRepository from "./connectGroupChatsRepository";
import dreamTeamChatsRepository from "./dreamTeamChatsRepository";
import pastorChatsRepository from "./pastorChatsRepository";
import experienceRepository from "./experienceRepository";
import locationRepository from "./locationRepository";
import eventRepository from "./eventRepository";
import miscellaneousAPIRepository from "./miscellaneousAPIRepository";
import badgesRepository from "./badgesRepository";
import userBadgesRepository from "./userBadgesRepository";
import userRepository from "./userRepository";
import announcementRepositoryt from "./announcementRepository";
import searchRepository from "./searchRepository";
import donationRepository from "./donationsRepository";
import prayerRepository from "./prayerRepository";
import locationAppActivitiesRepository from "./locationAppActivitiesRepository";
import givingVideosRepository from "./givingVideosRepository";
import classesRepository from "@/services/repositories/classesRepository";

const repositories = {
  "sign-up": signUpRepository,
  devotion: devotionsRepository,
  "daily-video": dailyVideosRepository,
  "worship-song": worshipSongsRepository,
  message: messagesRepository,
  "connect-group-chat": connectGroupChatsRepository,
  "dream-team-chat": dreamTeamChatsRepository,
  "pastor-chat": pastorChatsRepository,
  experience: experienceRepository,
  location: locationRepository,
  event: eventRepository,
  "miscellaneous-api": miscellaneousAPIRepository,
  badge: badgesRepository,
  "user-badge": userBadgesRepository,
  user: userRepository,
  announcement: announcementRepositoryt,
  search: searchRepository,
  donation: donationRepository,
  prayer: prayerRepository,
  "location-app-activities": locationAppActivitiesRepository,
  "giving-videos": givingVideosRepository,
  classes: classesRepository
};

export const RepositoryFactory = { get: name => repositories[name] };
