import Repository from "../repository";

let type = process.env.VUE_APP_API_TYPE;
let resource = "pastor-chats";

let url = `${type}/${resource}`;

export default {
  get(payload = {}) {
    return Repository.get(url, payload);
  },
  show(id, payload = {}) {
    return Repository.get(`${url}/${id}`, payload);
  },
  create(payload = {}) {
    return Repository.post(url, payload);
  },
  update(id, payload = {}) {
    console.log({ payload });

    return Repository.post(`${url}/${id}`, payload);
  }
};
