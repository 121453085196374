import Repository from "../repository";

let type = process.env.VUE_APP_API_TYPE;
let resource = "locations";

let url = `${type}/${resource}`;

export default {
  getUserDevotions(payload) {
    return Repository.get(`${url}/user-devotions`, payload);
  },
  getUserBadges(payload) {
    return Repository.get(`${url}/user-badges`, payload);
  },
  getUserPrayerRequests(payload) {
    return Repository.get(`${url}/prayer-request-users`, payload);
  },
  getPrayerRequest(payload) {
    return Repository.get(`${url}/prayer-requests`, payload);
  },
  getUserGoals(payload) {
    return Repository.get(`${type}/users/user-goals`, payload);
  }
};
