import Repository from "../repository";

let type = process.env.VUE_APP_API_TYPE;
let resource = "badges";

let url = `${type}/${resource}`;

export default {
  get(payload = {}) {
    return Repository.get(url, payload);
  },
  show(id, payload = {}) {
    return Repository.get(`${url}/${id}`, payload);
  },
  create(payload = {}) {
    return Repository.post(url, payload);
  },
  update(id, payload = {}) {
    return Repository.post(`${url}/${id}`, payload);
  },
  getCategories() {
    return [
      {
        name: "Church Attendance",
        value: "church_attendance"
      },
      {
        name: "Devotional",
        value: "devotional"
      },
      {
        name: "Social Media",
        value: "social_media"
      },
      {
        name: "Educational",
        value: "educational"
      },
      {
        name: "Discipleship",
        value: "discipleship"
      }
    ];
  }
};
