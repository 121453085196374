import Repository from "../repository";

let type = process.env.VUE_APP_API_TYPE;
let resource = "user-badges";

let url = `${type}/${resource}`;

export default {
  get(payload = {}) {
    return Repository.get(url, payload);
  },
  show(id, payload = {}) {
    return Repository.get(`${url}/${id}`, payload);
  },
  create(payload = {}) {
    return Repository.post(url, payload);
  },
  update(id, payload = {}) {
    return Repository.post(`${url}/${id}`, payload);
  },
  showDummyData() {
    return [
      {
        id: 1,
        url: require("@/assets/images/badges/inspire-app-badges_attend2.png"),
        description: "Attend 2 church events.",
        category: "church_attendance",
        date_acquired: new Date().toISOString().substr(0, 10)
      },
      {
        id: 2,
        url: require("@/assets/images/badges/inspire-app-badges_attend4.png"),
        description: "Attend 4 church events.",
        category: "church_attendance",
        date_acquired: new Date().toISOString().substr(0, 10)
      },
      {
        id: 3,
        url: require("@/assets/images/badges/inspire-app-badges_attend8.png"),
        description: "Attend 8 church events.",
        category: "church_attendance",
        date_acquired: new Date().toISOString().substr(0, 10)
      },
      {
        id: 4,
        url: require("@/assets/images/badges/inspire-app-badges_attend16.png"),
        description: "Attend 16 church events.",
        category: "church_attendance",
        date_acquired: new Date().toISOString().substr(0, 10)
      },
      {
        id: 5,
        url: require("@/assets/images/badges/inspire-app-badges-attend32.png"),
        description: "Attend 32 church events.",
        category: "church_attendance",
        date_acquired: new Date().toISOString().substr(0, 10)
      },
      {
        id: 6,
        url: require("@/assets/images/badges/inspire-app-badges_attend52_2.png"),
        description: "Attend 52 church events.",
        category: "church_attendance",
        date_acquired: new Date().toISOString().substr(0, 10)
      },
      {
        id: 7,
        url: require("@/assets/images/badges/inspire-app-badges_devo7.png"),
        description: "Completed 7 devotionals",
        category: "devotional",
        date_acquired: new Date().toISOString().substr(0, 10)
      },
      {
        id: 8,
        url: require("@/assets/images/badges/inspire-app-badges_14.png"),
        description: "Completed 14 devotionals",
        category: "devotional",
        date_acquired: new Date().toISOString().substr(0, 10)
      }
    ]
  },
  getDummyData() {
    return [
      { id: 1, name: "Bethany Murphy", count: 25 },
      { id: 2, name: "Tamara Hane", count: 25 },
      { id: 3, name: "Fermin Braun", count: 24 },
      { id: 4, name: "Eliza Hoppe", count: 23 },
      { id: 5, name: "Stephon Huel", count: 23 },
      { id: 6, name: "Lonie Kertzmann", count: 23 },
      { id: 7, name: "Ofelia Wisozk", count: 21 },
      { id: 8, name: "Bertram Ullrich", count: 20 },
      { id: 9, name: "Zetta Gleichner", count: 19 },
      { id: 10, name: "Rudolph Lind", count: 19 },
    ]
  }
};
