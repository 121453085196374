import Repository from "../repository";

let type = process.env.VUE_APP_API_TYPE;
let resource = "giving-videos";

let url = `${type}/${resource}`;

export default {
	get(payload = {}) {
		return Repository.get(url, payload);
	},

	create(payload = {}) {
		return Repository.post(url, payload);
	},

	update(id, payload = {}) {
		// payload._method = "PUT";
		return Repository.post(`${url}/${id}`, payload);
	},

	default() {
		return Repository.get(`api/giving-video/default`);
	}
}