import Repository from "../repository";

let type = process.env.VUE_APP_API_TYPE;
let resource = "experiences";

let url = `${type}/${resource}`;

function setPartPayload(data) {
  let payload = {};

  if (data.value === "note") {
    payload = {
      type: data.value,
      title: data.content.value.title,
      description: data.content.value.body,
      precedence: data.content.value.precedence
    };
  }

  if (data.value === "quote") {
    payload = {
      type: data.value,
      title: data.content.value.reference,
      description: data.content.value.body,
      precedence: data.content.value.precedence
    };
  }

  if (data.value === "image") {
    let formData = new FormData();

    formData.append("type", data.value);
    formData.append("image", data.content.value.file);
    formData.append("precedence", data.content.value.precedence);

    payload = formData;
  }

  if (data.value === "poll") {
    let choices = data.content.value.options.map((item, key) => {
      return {
        key,
        ...item
      };
    });

    payload = {
      type: data.value,
      title: data.content.value.question,
      description: data.content.value.body,
      choices: JSON.stringify(choices),
      precedence: data.content.value.precedence
    };
  }

  if (data.value === "user_note") {
    payload = {
      type: data.value,
      precedence: data.content.value.precedence
    };
  }

  return payload;
}

export default {
  get(payload = {}) {
    return Repository.get(url, payload);
  },
  show(id, payload = {}) {
    return Repository.get(`${url}/${id}`, payload);
  },
  create(payload = {}) {
    return Repository.post(url, payload);
  },
  update(id, payload = {}) {
    payload._method = "PUT";

    return Repository.post(`${url}/${id}`, payload);
  },
  createPart(experienceId, data = {}) {
    let payload = setPartPayload(data);

    return Repository.post(`${url}/${experienceId}/parts`, payload);
  },
  showParts(experienceId, payload = {}) {
    return Repository.get(`${url}/${experienceId}/parts`, payload);
  },
  updatePart(experienceId, partId, data = {}) {
    let payload = setPartPayload(data);
    payload._method = "PUT";

    return Repository.post(`${url}/${experienceId}/parts/${partId}`, payload);
  },
  deletePart(experienceId, partId) {
    return Repository.delete(`${url}/${experienceId}/parts/${partId}`);
  },
  getExperienceDummyData(numberOfData = 15) {
    let items = [];

    for (let index = 0; index <= numberOfData; index++) {
      let id = index + 1;

      items.push({
        id,
        title: `Title ${index}`,
        scheduled_at: new Date().toISOString().substr(0, 10)
      });
    }

    return items;
  },
  getServicesComponentDummyData() {
    return [
      {
        componentName: "ImageComponent",
        value: "image",
        content: {
          value: {
            image: "https://picsum.photos/id/11/500/300"
          }
        }
      },
      {
        componentName: "NoteComponent",
        value: "note",
        content: {
          value: {
            title: "test",
            body: "Body"
          }
        }
      },
      {
        componentName: "QuoteComponent",
        value: "quote",
        content: {
          value: {
            reference: "test",
            body: "Body"
          }
        }
      },
      {
        componentName: "PollComponent",
        value: "poll",
        content: {
          value: {
            question: "test?",
            options: [
              { text: "option1" },
              { text: "option2" },
              { text: "option3" }
            ]
          }
        }
      }
    ];
  }
};
